import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BorderedLink from '../../../shared/Links/BorderedLink';

const Article = () => {
  const { t } = useTranslation();
  const { article, link } = t('solutions', { returnObjects: true });
  return (
    <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto pt-12 px-8-lg">
      <div className="w-full h-full relative me-auto-lg w-90 mx-auto">
        <div className="h-75 w-75 border-4 border-blue-400 absolute -top-7 -left-10" />
        <StaticImage
          src="../../../images/homepage/solution-img.jpg"
          layout="constrained"
          quality={100}
          objectFit="cover"
          objectPosition="center"
          className="absolute-lg top-0 left-0 h-full max-h-full max-w-full"
          alt="Solution image"
        />
      </div>
      <div className="flex flex-col justify-start items-start w-90-lg ms-auto py-8">
        <span className="text-2xl-xxl text-xl-lg text-lg text-blue-400 font-medium mb-3">
          {article.label}
        </span>
        <h3 className="font-bold text-blue-900 text-5xl-xxl text-4xl-lg text-3xl mb-6">
          {article.title}
        </h3>
        <p className="font-light text-blue-800 text-xl-xxl text-base mb-8">
          {article.description}
        </p>
        <BorderedLink withArrow color="blue-800" to={link.to}>
          {link.label}
        </BorderedLink>
      </div>
    </div>
  );
};

export default Article;
