import PropTypes from 'prop-types';
import React from 'react';
import { API_URL } from '../../../api/axios';

const Projects = ({ list }) => {
  return (
    <div className="grid grid-cols-3-lg grid-cols-1 gap-8-xxl gap-6 w-90 h-400-px-lg mx-auto">
      {list?.map((item) => (
        <div
          key={item.id}
          className="flex items-end justify-center p-12 relative h-full h-300-px"
        >
          <img
            src={`${API_URL}${item?.attributes?.cover?.data?.attributes?.url}`}
            className="absolute top-0 left-0 h-full w-full max-w-full object-cover"
            height={item?.attributes?.cover?.data?.attributes?.height}
            width={item?.attributes?.cover?.data?.attributes?.height}
            alt={item?.attributes?.name}
          />
          <div className="absolute top-0 left-0 h-full w-full max-w-full object-cover bg-blue-900-50" />
          <span className="font-bold text-lg-xxl text-base text-white text-center z-10 relative">
            {item?.attributes?.name}
          </span>
        </div>
      ))}
    </div>
  );
};

Projects.propTypes = {
  list: PropTypes.array,
};

export default Projects;
