import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../shared/Links';

const CTASection = () => {
  const { t } = useTranslation();
  const { title, description, phoneNumber } = t('CTASection', {
    returnObjects: true,
  });
  return (
    <div className="container w-90-lg w-full mx-auto flex relative mt-12-lg px-6 w-90-lg w-full">
      <div className="bg-blue-900 w-full relative">
        <div className="flex flex-col items-start w-40-lg py-20-xl py-12 px-6 pe-0-lg ps-20-lg">
          <span className="text-5xl-xxl text-4xl font-bold text-white mb-6-xxl mb-4">
            {title}
          </span>
          <p className="text-base-xxl text-sm font-light text-white mb-6-xxl mb-4">
            {description}
          </p>
          <CustomLink withArrow to={phoneNumber.to}>
            {phoneNumber.label}
          </CustomLink>
        </div>
        <StaticImage
          src="../../../images/homepage/cta-section.png"
          className="right-0 bottom-0 h-auto w-65 absolute max-h-auto-xxl max-h-450-px hidden inline-block-lg"
          objectFit="contain"
          objectPosition="bottom 0 right 0"
          quality={100}
          height={540}
          alt="Building image"
        />
      </div>
    </div>
  );
};

export default CTASection;
