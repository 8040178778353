import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../shared/Links';

const list = [
  {
    Image: () => (
      <StaticImage
        src="../../../images/homepage/article-1.jpeg"
        layout="constrained"
        objectFit="cover"
        className="w-full mb-3 h-300-px"
        quality={100}
        alt="Article"
      />
    ),
    date: '11 Juillet 23',
    title:
      'CHRYSO®GreenDecap 300, nouvel agent décapant béton, sans chlorure, au profil HSE élevé',
    description:
      'CHRYSO, marque de Saint-Gobain, complète sa gamme de décapants béton pour le matériel avec la solution CHRYSO®....',
  },
  {
    Image: () => (
      <StaticImage
        src="../../../images/homepage/article-2.jpeg"
        layout="constrained"
        objectFit="cover"
        className="w-full mb-3 h-300-px"
        quality={100}
        alt="Article"
      />
    ),
    date: '11 Juillet 23',
    title:
      'L’offre d’adjuvants et de services dédiée aux sables et granulats complexes locaux...',
    description:
      'Pour accompagner les acteurs de la construction durable, CHRYSO renforce son offre CHRYSO®Quad et ses services associés...',
  },
  {
    Image: () => (
      <StaticImage
        src="../../../images/homepage/article-3.jpeg"
        layout="constrained"
        objectFit="cover"
        className="w-full mb-3 h-300-px"
        quality={100}
        alt="Article"
      />
    ),
    date: '11 Juillet 23',
    title:
      'CHRYSO et Hoffmann Green Technologies, partenaires pour le développement des bétons...',
    description:
      'CHRYSO (Saint-Gobain Chimie de la Construction) annonce le développement d’une solution pour la mise en œuvre des bétons...',
  },
];

const Articles = () => {
  const { t } = useTranslation();
  const {
    title: sectionTitle,
    label,
    description: sectionDescription,
    articleLink,
    link,
  } = t('articles', { returnObjects: true });
  return (
    <div className="py-20-xxl py-12 container px-6 mx-auto w-90-lg w-full">
      <div className="flex flex-col">
        <span className="inline-block mb-4 font-medium text-2xl-xxl text-xl-lg text-lg text-blue-400 text-center">
          {sectionTitle}
        </span>
        <h2 className="text-blue-900 font-bold text-5xl-xxl text-4xl-lg text-3xl text-center mb-5 text-center">
          {label}
        </h2>
        <span className="inline-block w-44-px h-7-px bg-blue-400 mb-6 mx-auto text-center" />
        <p className="text-gray-400 text-base-xxl text-sm font-light text-center mb-8 max-w-450-px mx-auto">
          {sectionDescription}
        </p>
        <div className="grid grid-cols-3-lg gap-12-xxl gap-8 mb-20-xxl mb-16">
          {list.map(({ Image, title, date, description }) => (
            <div key={title} className="flex flex-col items-start">
              <Image />
              <span className="inline-block mb-2 font-medium text-base-xxl text-sm text-blue-400">
                {date}
              </span>
              <h3 className="font-bold text-xl-xxl text-lg text-blue-900 mb-3 line-clamp-3">
                {title}
              </h3>
              <p className="text-gray-400 text-base-xxl text-sm font-light mb-6 line-clamp-3">
                {description}
              </p>

              <Link
                to="/"
                className="font-bold text-sm-xxl text-xs text-blue-800 underline-blue relative"
                onClick={(e) => e.stopPropagation()}
              >
                {articleLink}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center">
        <CustomLink withArrow to={link.to}>
          {link.label}
        </CustomLink>
      </div>
    </div>
  );
};

export default Articles;
