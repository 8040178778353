import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../api/axios';

const ClientCarousel = ({ list }) => {
  const { t } = useTranslation();
  const { title } = t('clients', { returnObjects: true });
  const ITEMS_TO_SHOW = 4;
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll = (index) => () => {
    carouselRef.current.scrollLeft = carouselRef.current.clientWidth * index;
    setCurrentIndex(index);
  };

  return (
    <div className="py-20-xxl py-12 container mx-auto flex flex-col w-90-lg w-full">
      <h2 className="text-blue-900 font-bold text-5xl-xxl text-4xl text-center mb-8 text-center">
        {title}
      </h2>
      <div
        ref={carouselRef}
        className="flex items-center overflow-auto flex-1 mb-4 no-scrollbar smooth-scroll"
      >
        {list.map((item) => (
          <div
            key={item.id}
            className="min-w-25-lg w-25-lg max-w-25-lg w-50 min-w-50 px-4 text-center"
          >
            <img
              key={item.id}
              src={`${API_URL}${item?.attributes?.logo?.data?.attributes?.url}`}
              height={70}
              className="object-contain w-auto max-h-70-px"
              alt={item?.attributes?.name ?? 'Partner logo'}
            />
          </div>
        ))}
      </div>
      <div className="items-center justify-center gap-2 hidden flex-lg">
        {Array(Math.ceil(list.length / ITEMS_TO_SHOW))
          .fill(0)
          .map((_, index) => (
            <button
              key={index}
              type="button"
              className={`transition-all rounded w-10-px h-10-px bg-transparent border-0 ${currentIndex === index ? 'bg-blue-400' : 'border border-gray-200'}`}
              onClick={handleScroll(index)}
            />
          ))}
      </div>
    </div>
  );
};

ClientCarousel.propTypes = {
  list: PropTypes.array,
};

export default ClientCarousel;
