import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Phone } from '../../../shared/Icons/Phone';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../shared/Links';
import BorderedLink from '../../../shared/Links/BorderedLink';

const Service = () => {
  const { t } = useTranslation();
  const { title, description, phoneNumber, contact } = t('service', {
    returnObjects: true,
  });
  return (
    <div className="grid grid-cols-2-lg place-items-center max-w-1200 mx-auto py-20 px-8 w-90-lg w-full">
      <div className="w-90 h-full relative me-auto">
        <div className="h-75 w-75 border-4 border-blue-400 absolute -top-7 -right-10" />
        <StaticImage
          src="../../../images/homepage/service-img.jpeg"
          quality={100}
          className="object-cover h-full w-full"
          alt="Service image"
        />
      </div>
      <div className="flex flex-col justify-start items-start w-90-lg ms-auto">
        <h3 className="font-bold text-blue-900 text-5xl-xxl text-4xl mb-6-xxl mb-4 mt-6 mt-0-lg">
          {title}
        </h3>
        <p className="font-light text-blue-800 text-xl mb-8-xxl mb-6">
          {description}
        </p>
        <div className="flex flex-wrap items-center gap-5">
          <CustomLink to={phoneNumber.to}>
            <span className="inline-block me-2">
              <Phone />
            </span>
            {phoneNumber.label}
          </CustomLink>
          <BorderedLink withArrow color="blue-400" to={contact.to}>
            {contact.label}
          </BorderedLink>
        </div>
      </div>
    </div>
  );
};

export default Service;
