import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const CustomLink = ({ children, withArrow = false, to }) => {
  return (
    <Link
      to={to}
      className="flex items-center justify-center bg-blue-400 text-white py-5-xxl py-4 px-8-xxl px-6 text-base-xl text-sm font-medium border border-blue-400"
    >
      {children}
      {withArrow && (
        <StaticImage
          src="../../images/icons/right-arrow.svg"
          placeholder="none"
          className="ms-3"
          quality={100}
          alt="right arrow icon"
        />
      )}
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node,
  withArrow: PropTypes.bool,
  to: PropTypes.string,
};

export default CustomLink;
