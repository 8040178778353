import React from 'react';
import PropTypes from 'prop-types';
import { RightArrown } from '../Icons/RightArrow';
import { Link } from 'gatsby';

const BorderedLink = ({ children, withArrow = false, color = 'white', to }) => {
  const iconColor = {
    white: '#ffffff',
    'blue-400': '#2489CD',
    'blue-800': '#163252',
  };
  return (
    <Link
      to={to}
      className={`flex items-center justify-center bg-transparent gap-3 border border-${color} text-${color}  py-5-xxl py-4 px-8-xxl px-6 text-base-xl text-sm font-medium`}
    >
      {children}
      {withArrow && <RightArrown fill={iconColor[color]} />}
    </Link>
  );
};

BorderedLink.propTypes = {
  children: PropTypes.node,
  withArrow: PropTypes.bool,
  color: PropTypes.string,
  to: PropTypes.string,
};

export default BorderedLink;
