import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../../../api/axios';

const Partners = ({ list }) => {
  const { t } = useTranslation();
  const { title } = t('partners', { returnObjects: true });

  return (
    <div className="py-20-xxl py-12 container px-6 mx-auto w-90-lg w-full mx-auto">
      <h2 className="text-blue-900 font-bold text-5xl-xxl text-4xl-lg text-3xl text-center mb-20-xxl mb-16">
        {title}
      </h2>
      <div className="grid grid-cols-4-lg grid-cols-2 gap-8 justify-center items-center">
        {list?.map((item) => (
          <img
            key={item.id}
            src={`${API_URL}${item?.attributes?.logo?.data?.attributes?.url}`}
            height={70}
            className="object-contain w-auto max-h-70-px"
            alt={item?.attributes?.name ?? 'Partner logo'}
          />
        ))}
      </div>
    </div>
  );
};

Partners.propTypes = {
  list: PropTypes.array,
};

export default Partners;
