import React from 'react';
import { useTranslation } from 'react-i18next';

const Characteristics = () => {
  const { t } = useTranslation();
  const { characteristics } = t('history', { returnObjects: true });
  return (
    <div className="grid grid-cols-2 justify-between gap-12-xxl gap-8 w-full">
      {characteristics.map((item) => (
        <div key={item.label} className="flex flex-col max-w-200-px">
          <span className="text-6xl-xxl text-4xl-lg text-3xl font-bold text-white mb-2 inline-block">
            {item.value}
          </span>
          <span className="inline-block w-44-px h-7-px bg-blue-400 mb-2" />
          <span className="text-xl-xxl text-base text-white">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Characteristics;
