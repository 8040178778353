import { Link } from 'gatsby';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API_URL } from '../../../api/axios';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const Card = ({ baseUrl, cover, icon, name, slug, description }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="grid grid-rows-3 place-items-center w-full h-2xl-700-px h-500-px relative overflow-hidden py-16 transition-all">
      <button
        type="button"
        className="absolute top-0 left-0 h-full w-full opacity-0 z-10"
        onClick={() => setShow((prev) => !prev)}
      ></button>
      <img
        src={`${API_URL}${cover?.data?.attributes?.url}`}
        className="w-full h-full object-cover absolute top-0 left-0 -z-20"
        alt={name}
      />

      <div
        className={`absolute top-0 left-0 w-full h-full p-5 ${show ? 'bg-blue-400-87' : 'bg-blue-800-70'} -z-10`}
      >
        <div
          className={`w-full h-full border border-white ${!show ? 'opacity-0' : ''}`}
        />
      </div>
      <div className="opacity-0">
        <img
          src={`${API_URL}${icon?.data?.attributes?.url}`}
          height={80}
          width={80}
          className="object-contain"
          alt="Icon"
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <img
          src={`${API_URL}${icon?.data?.attributes?.url}`}
          height={80}
          width={80}
          className="object-contain"
          alt="Icon"
        />
        <span className="font-bold text-white text-2xl-xxl text-lg mt-4 text-center inline-block px-6">
          {name}
        </span>
      </div>
      <div
        className={`text-center max-w-320-px w-full p-5 mx-auto ${!show ? 'opacity-0' : ''}`}
      >
        <div className="text-white text-base-xxl text-sm mt-12 mb-6 text-center px-3">
          <BlocksRenderer content={description} />
        </div>
        <Link
          to={`${baseUrl}/${slug}`}
          className="font-bold text-sm-xxl text-xs text-white text-center custom-underline relative z-10"
          onClick={(e) => e.stopPropagation()}
        >
          Read more
        </Link>
      </div>
    </div>
  );
};

Card.propTypes = {
  baseUrl: PropTypes.string,
  cover: PropTypes.object,
  icon: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.array,
  slug: PropTypes.string,
};

export default Card;
