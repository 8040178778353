import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Characteristics from './Characteristics';
import { useTranslation } from 'react-i18next';

const History = () => {
  const { t } = useTranslation();
  const { title, description } = t('history', { returnObjects: true });
  return (
    <div className="relative py-16 w-full w-90-lg w-full mx-auto">
      <StaticImage
        src="../../../images/homepage/history-bg.jpg"
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
        quality={100}
        alt="History background"
      />
      <div className="grid grid-cols-2-lg gap-28-xxl gap-20 place-items-center container mx-auto px-6">
        <div>
          <span className="text-6xl-xxl text-4xl-lg text-3xl font-bold text-white mb-6-xxl mb-4 inline-block pe-8">
            {title}
          </span>
          <p className="font-light text-xl-xxl text-base text-white">
            {description}
          </p>
        </div>
        <Characteristics />
      </div>
    </div>
  );
};

export default History;
