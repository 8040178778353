import React from 'react';
export const Phone = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 1H7.5L9.5 6L7 7.5C8.07096 9.67153 9.82847 11.429 12 12.5L13.5 10L18.5 12V16C18.5 16.5304 18.2893 17.0391 17.9142 17.4142C17.5391 17.7893 17.0304 18 16.5 18C12.5993 17.763 8.92015 16.1065 6.15683 13.3432C3.3935 10.5798 1.73705 6.90074 1.5 3C1.5 2.46957 1.71071 1.96086 2.08579 1.58579C2.46086 1.21071 2.96957 1 3.5 1Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
