import React from 'react';
import PropTypes from 'prop-types';
import Cards from './Cards';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../shared/Links';

const References = ({ references }) => {
  const { t } = useTranslation();
  const { label, title, description, link } = t('references', {
    returnObjects: true,
  });
  return (
    <div className="flex flex-col w-90-lg w-full mx-auto">
      <div className="py-12-xxl py-10 container mx-auto px-6 flex flex-wrap items-center">
        <div className="w-50-lg">
          <span className="inline-block mb-4 font-medium text-2xl-xxl text-xl-lg text-lg text-blue-400">
            {label}
          </span>
          <h3 className="font-bold text-5xl-xxl text-4xl-lg text-3xl text-blue-800 mb-6 w-90-lg">
            {title}
          </h3>
          <div className="flex">
            <CustomLink withArrow to={link.to}>
              {link.label}
            </CustomLink>
          </div>
        </div>
        <p className="font-light text-blue-800 text-xl-xxl text-base w-50-lg ps-12-lg mt-6 mt-0-lg">
          {description}
        </p>
      </div>
      <Cards baseUrl="/references-chantiers" references={references} />
    </div>
  );
};

References.propTypes = {
  references: PropTypes.array,
};

export default References;
