import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import Projects from './Projects';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../shared/Links';
import BorderedLink from '../../../shared/Links/BorderedLink';

const Hero = ({ references }) => {
  const { t } = useTranslation();
  const { companyName, title, ctaButton, contact } = t('hero', {
    returnObjects: true,
  });
  return (
    <div className="pt-218-px-lg pt-100-px relative w-full">
      <div className="py-12-xxl py-10 w-90-lg w-full mx-auto">
        <StaticImage
          loading="eager"
          src="../../../images/homepage/hero-bg.jpg"
          placeholder="dominantColor"
          quality={100}
          layout="constrained"
          className="absolute top-0 left-0 h-full w-full object-cover -z-10"
          alt="Hero background"
        />
        <div className=" w-full max-w-1400 mx-auto px-6">
          <h1 className="text-blue-400 text-2xl-xxl text-xl-lg text-lg text-center mb-4 font-medium">
            {companyName}
          </h1>
          <h2 className="font-bold text-5xl-xxl text-4xl-lg text-2xl text-white text-center max-w-1000-xl max-w-700 mx-auto mb-8 w-50-lg">
            {title}
          </h2>
          <div className="flex flex-wrap flex-col flex-row-lg items-center justify-center mb-10">
            <div className="me-4-lg mb-4 mb-0-lg">
              <CustomLink withArrow to="/references-chantiers">
                {ctaButton}
              </CustomLink>
            </div>
            <BorderedLink color="white" to="/contact">
              {contact}
            </BorderedLink>
          </div>
          <Projects list={references} />
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  references: PropTypes.array,
};

export default Hero;
